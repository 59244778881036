<template>
    <footer class="footer flex flex-col md:flex-row space-y-10 md:space-y-0 py-10 md:py-0">
        <div class="w-full md:w-1/3 flex items-center justify-center md:justify-start pl-0 md:pl-14 space-x-4">
            <a v-if="brand.privacity" :href="brand.privacity">
                Aviso Legal
            </a>
            <a v-if="brand.help" :href="brand.help">
                Ayuda
            </a>
            <a v-if="brand.terms" :href="brand.terms">
                Términos y condiciones
            </a>
        </div>
        <div class="w-full md:w-1/3 flex items-center justify-center">
            <div class="rrss flex items-center justify-evenly">
            <a v-if="brand.instagram" :href="brand.instagram">
							<img :src="require('@/assets/images/ic-instagram.png')" alt="icono de instagram">
						</a>
            <a v-if="brand.tiktok" :href="brand.tiktok">
							<img :src="require('@/assets/images/ic-tiktok.png')" alt="icono de tiktok">
						</a>
            <a v-if="brand.x" :href="brand.x">
							<img :src="require('@/assets/images/ic-x.svg')" alt="icono de x">
						</a>
            <a v-if="brand.youtube" :href="brand.youtube">
							<img :src="require('@/assets/images/ic-youtube.png')" alt="icono de youtube">
						</a>
            <a v-if="brand.facebook" :href="brand.facebook">
							<img :src="require('@/assets/images/ic-facebook.png')" alt="icono de facebook">
						</a>
            <a v-if="brand.linkedin" :href="brand.linkedin">
							<img :src="require('@/assets/images/ic-linkedin.png')" alt="icono de linkedin">
						</a>
            <a v-if="brand.spoty" :href="brand.spoty">
							<img :src="require('@/assets/images/ic-spoty.png')" alt="icono de spoty">
						</a>
            </div>
        </div>
        <div class="w-full md:w-1/3 flex items-center justify-center md:justify-end pr-0 md:pr-14">
          Powered by
			<a href="https://www.wegow.com" target="_blank">
            <img :src="require('@/assets/images/ic-wegow-topaz.png')" alt="icono de wegow" class="pl-4">
            </a>
		</div>
        
    </footer>
</template>

<script setup>
const brand = {
	privacity:'https://www.wegow.com/es/aviso-legal',
	help:'https://www.wegow.com/es/ayuda',
	terms:'https://www.wegow.com/es/terminos-y-condiciones',
	// instagram:'https://www.google.com/',
	// tiktok:'https://www.google.com/',
	// x:'https://www.google.com/',
	// youtube:'https://www.google.com/',
	// facebook:'https://www.google.com/',
	// linkedin:'https://www.google.com/',
	// spoty:'https://www.google.com/',
}

</script>

<style lang="scss" scoped>
.footer {
	margin-top: auto;
	background-color: $backgroundFooter;
	min-height: $minHeightFooter;
	.rrss {
		max-width: 300px;
		width: 100%;
		img {
			height: 22px;
			width: auto;
		}
	}
}
</style>
