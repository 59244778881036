<template>
  <div
    :class="{
      fadeIn: openModal,
      fadeOut: !openModal,
      center: position === 'center',
      fixed: position === 'fixed'
    }"
    class="modal-mask animate__animated overflow-scroll"
    @keydown.esc="emit('close')"
  >
    <div class="absolute right-4 z-10 top-4">
      <img
        v-if="externalClose"
        style="{height: 20px; width: 20px;}"
        class="cursor-pointer"
        :class="closeColor + ' ' + closePosition"
        :src="require('@/assets/images/modal-cross.png')"
        @click="emit('close')"
      />
    </div>
    <div
      id="modalTemplate"
      ref="modal-template"
      :class="{
        successErrorDialog: successErrorDialog === 'success-error-dialog',
        fadeInDown: openModal,
        fadeOutUp: !openModal,
        small: size === 'small',
        'intermedium-small': size === 'intermedium-small',
        'normal-small': size === 'normal-small',
        intermedium: size === 'intermedium',
        'intermedium-large': size === 'intermedium-large',
        'intermedium-no-margin': size === 'intermedium-no-margin',
        none: size === 'none',
        normal: size === 'normal',
        large: size === 'large',
        complete: size === 'complete',
        'extra-large': size === 'extra-large',
        posR: !title,
        'overflow-hidden': overflowHidden
      }"
      class="modal-template animate__animated animate__fadeInDown"
    >
      <div ref="modal" class="modal-container">
        <div
          v-if="!title && !isStatic && close && noArrow === false"
          class="absolute right-4 z-10 top-4"
        >
          <img
            style="{height: 20px; width: 20px;}"
            class="cursor-pointer"
            :src="require('@/assets/images/modal-cross.png')"
            :class="closeColor + ' ' + closePosition"
            @click="emit('close')"
          />
        </div>
        <!-- <nuxt-link v-else-if="(!title && isStatic && close)"
                 to="/"
                 class="close-arrow"
                 :class="{'left': closePosition === 'left'}" /> -->
        <div v-else-if="title" class="modal-template-header">
          <h3>{{ title }}</h3>
          <nuxt-link
            v-if="isStatic && close"
            to="/"
            class="close-arrow"
            :class="{ left: closePosition === 'left' }"
            @click="emit('close')"
          />
          <span
            v-else-if="close"
            class="close-arrow"
            :class="{ left: closePosition === 'left' }"
            @click="emit('close')"
          />
          <slot name="header" />
        </div>
        <div class="modal-template-body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, toRefs } from "vue";
import useDetectOutsideClick from "@/plugins/useDetectOutsideClick";
// import WIcon from "../ui/elements/WIcon.vue";
import { defineProps, defineEmits } from 'vue';
const props = defineProps({
  cancelOutsideClick: {
    type: Boolean,
    default: false
  },
  successErrorDialog: {
    type: String,
    default: ""
  },
  title: {
    type: String,
    default: ""
  },
  size: {
    type: String,
    default: "normal"
  },
  isStatic: {
    type: Boolean,
    default: false
  },
  close: {
    type: Boolean,
    default: true
  },
  externalClose: {
    type: Boolean,
    default: false
  },
  closePosition: {
    type: String,
    default: "right"
  },
  noArrow: {
    type: Boolean,
    required: false,
    default: false
  },
  closeColor: {
    type: String,
    default: ""
  },
  position: {
    type: String,
    default: "fixed"
  },
  overflowHidden: {
    type: Boolean,
    default: false
  }
});
const { cancelOutsideClick } = toRefs(props);
const emit = defineEmits(["close"]);

const openModal = ref(true);
const modal = ref(null);
const isOpen = ref(false);

const handleEsc = e => e.keyCode === 27 && emit("close");

onMounted(() => setTimeout(() => (isOpen.value = true), 100));
onUnmounted(() => {
  document.getElementsByTagName("body")[0].classList.remove("no-scroll");
  window.removeEventListener("keyup", handleEsc);
});

useDetectOutsideClick(
  modal,
  () => !cancelOutsideClick.value && isOpen.value && emit("close")
);

const init = () => {
  let body = document.getElementsByTagName("body")[0];
  body.className += " no-scroll";
  if (props.close) {
    window.addEventListener("keyup", handleEsc);
  }
};

init();
</script>

<style lang="scss">
@import "./modal-template.scss";
.container-access-code .modal-mask .modal-template.intermedium {
  padding: 0px !important;
}
body.no-scroll {
  overflow: hidden;
}
.close-arrow.black::before {
  color: black !important;
}
.modal-template-header {
  background-color: $inputLigthBackground !important;
  border-radius: 0 !important;
}
.external-close {
  right: 15px;
  top: 9px;
  display: block;
  width: 25px;
  font-size: 25px;
  height: 25px;
  position: absolute;
  cursor: pointer;
}
</style>
