import { createRouter, createWebHistory } from "vue-router";

const MainPage = () => import("@/views/MainPage.vue");
const EventsList = () => import("@/views/EventsList.vue");
const EventProfileSimple = () => import("@/views/EventProfileSimple.vue");
// const EventProfile = () => import("@/views/EventProfile.vue");
const EventPurchase = () => import("@/views/EventPurchase.vue");
const NotFound = () => import("@/views/NotFound.vue");

// const finalUrl = "/landing/marca-blanca-1";
const finalUrl = "";

export const routes = [
    {
      path: finalUrl+"/",
      name: "home",
      component: MainPage,
      meta: {
        title: "Entradas de conciertos y festivales | EsMiEntrada"
      }
    },
    {
      path: finalUrl+"/lista",
      name: "EventsList",
      component: EventsList,
      meta: {
        title: "Entradas de conciertos y festivales | EsMiEntrada"
      }
    },
    {
      path: finalUrl+"/conciertos/:slug",
      name: "EventProfile",
      component: EventProfileSimple,
      alias: [
        finalUrl+"/konzerte/:slug", // de
        finalUrl+"/concerts/:slug", // en
        finalUrl+"/concerts/:slug", // fr
        finalUrl+"/concerti/:slug", // it
        finalUrl+"/concertos/:slug" // pt
      ],
      meta: {
        title: "EsMiEntrada - Eventos"
      }
    },
    {
      path: finalUrl+"/festivales/:slug",
      name: "FestivalProfile",
      component: EventProfileSimple,
      // component: EventProfile,
      alias: [
        finalUrl+"/festivals/:slug", // de
        finalUrl+"/festivals/:slug", // en
        finalUrl+"/festivals/:slug", // fr
        finalUrl+"/festival/:slug", // it
        finalUrl+"/festivais/:slug" // pt
      ],
      meta: {
        title: "EsMiEntrada - Eventos"
      }
    },
    {
      path: finalUrl+"/compra/:slug",
      name: "EventPurchase",
      component: EventPurchase,
      alias: [
        finalUrl+"/kauf/:slug", // de
        finalUrl+"/purchase/:slug", // en
        finalUrl+"/achat/:slug", // fr
        finalUrl+"/acquisto/:slug", // it
        finalUrl+"/compra/:slug" // pt
      ],
      meta: {
        title: "EsMiEntrada - Compra"
      }
    },
    {
      path: finalUrl+"/:catchAll(.*)",
      component: NotFound,
      meta: {
        title: "Página no encontrada"
      }
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 })
});
  
router.beforeEach(async (to, from, next) => {

  if (to.meta?.title) {
    document.title = to.meta?.title;
  } else {
    document.title = "▷ EsMiEntrada | 2023 2024";
  }

  if (!to.meta.middleware) {
    return next();
  }
  const { middleware } = to.meta;


  const context = {
    to,
    from,
    next
    // store,
    // permissions: to.meta.permission
  };

  router.afterEach(() => {
    window.scrollTo(0, 0);
  });

  return middleware[0]({
    ...context,
    // next: middlewarePipeline(context, middleware, 1)
  });
});

export default router;