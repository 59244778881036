<template>
    <header
      id="header"
      class="flex m-auto max-width-navbar justify-start items-center w-full"
    >
        <router-link :to="{ name: 'home' }">
            <img
                :src="require('@/assets/logo.png')"
                class="logo-wegow ml-4"
                alt="Conciertos Wegow"
            />
        </router-link>

    </header>
</template>

<script setup>

</script>

<style scoped lang="scss">
header {
    background-color: $backgroundNavbar;
    height: $heightNavbar;
    background-image: url('@/assets/images/background-top-navbar.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: sticky;
    z-index: 5;
    top: 0;
    img {
        width: $widthIconNavbar;
        height: $heightIconNavbar;
    }
}
</style>