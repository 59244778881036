<template>
  <div>
    <top-navbar />
    <router-view></router-view>

    <!-- Modals: -->
    <!-- <w-loader-complete /> -->
    <modal-error />
    <modal-image-preview v-bind="previewModalProps" />
  </div>
  <main-footer v-if="route.name !== 'EventPurchase'" />
</template>

<script setup>
import TopNavbar from "@/components/main-components/TopNavbar";
import MainFooter from "@/components/main-components/MainFooter";

// Modals:
import ModalError from "@/components/modals/ModalError.vue";
import ModalImagePreview from "@/components/modals/ModalImagePreview.vue";
import { useModalHandler } from "@/plugins/useModalHandler";

import { useRoute } from "vue-router";

const route = useRoute();

const {
  previewModalProps
} = useModalHandler();
</script>

<style lang="scss">
#app {
  min-height: 100vh;
  background-color: $backgroundweb;
  color: $textColor;
  display: flex;
  flex-direction: column;
}

</style>
