<template>
  <modal-template
    v-if="previewModal === IMAGE_PREVIEW"
    size="none"
    @close="closePreviewModal"
  >
    <img v-if="img" :src="img" :alt="title" />
    <iframe
      v-else-if="video"
      loading="lazy"
      :src="video"
      :title="title"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
      class="w-full h-full"
    />
  </modal-template>
</template>
<script setup>
import ModalTemplate from "./ModalTemplate.vue";

import { useModalHandler } from "@/plugins/useModalHandler";
import { defineProps } from 'vue';

defineProps({
  img: { type: String, default: undefined },
  video: { type: String, default: undefined },
  title: { type: String, default: undefined }
});

const { closePreviewModal, previewModal, IMAGE_PREVIEW } = useModalHandler();
</script>
<style lang="scss" scoped>
img {
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
}
</style>
