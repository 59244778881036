<template>
  <modal-template
    v-if="errorModal !== undefined"
    size="intermedium"
    @close="closeModal"
  >
    <div class="row">
      <div v-if="errorModal.modal.imageType !== 'none'" class="col-12">
        <figure class="picture">
          <picture>
            <img
              :src="getModalImage"
              class="lazyload"
              :alt="$t('wegow.error.altErrorIcon')"
            />
          </picture>
        </figure>
      </div>
      <div class="col-12 mB15 mT5">
        <h2 v-if="errorModal.modal.title" id="modal-error-title">
          {{ $t(errorModal.modal.title) }}
        </h2>
        <p
          v-if="errorModal.modal.description"
          id="modal-error-text"
          v-html="$t(errorModal.modal.description)"
        ></p>
        <br />
        <div class="flex justify-center space-x-2">
          <wegow-button
            v-if="errorModal.modal.btn_message"
            :text="$t(errorModal.modal.btn_message)"
            size="xl"
            color="topaz-light"
            background="opacity"
            @click="close()"
          ></wegow-button>
          <wegow-button
            v-if="errorModal.modal.btn_red_message"
            :text="$t(errorModal.modal.btn_red_message)"
            size="xl"
            color="red"
            background="opacity"
            @click="closeRed()"
          ></wegow-button>
        </div>
      </div>
    </div>
  </modal-template>
</template>

<script setup>
import ModalTemplate from "@/components/modals/ModalTemplate.vue";
import WegowButton from "@/components/forms/WegowButton";
import { useModalHandler } from "@/plugins/useModalHandler";
import { computed } from "vue";
import { defineProps } from 'vue';

const { errorModal, closeErrorModal } = useModalHandler();

defineProps({
  size: {
    type: String,
    required: false,
    default: "small"
  }
});

const getModalImage = computed(() => {
  // TODO: revisar fotos
  // if (errorModal.value.modal.imageType === "accept") {
  //   return require("@/assets/images/ic-accept.svg");
  // } else if (errorModal.value.modal.imageType === "accept-topaz") {
  //   return require("@/assets/images/ic-accept-topaz.svg");
  // } else if (errorModal.value.modal.imageType === "info") {
  //   return require("@/assets/images/ic-info.svg");
  // }
  return require("@/assets/images/ic-error-notice.svg");
});

const closeModal = () => {
  if (errorModal.value.modal.close_method) {
    errorModal.value.modal.close_method();
    closeErrorModal();
  } else {
    closeErrorModal();
  }
};

const close = () => {
  if (errorModal.value.modal.button_method) {
    errorModal.value.modal.button_method();
    closeErrorModal();
  } else {
    closeErrorModal();
  }
};
const closeRed = () => {
  if (errorModal.value.modal.button_red_method) {
    errorModal.value.modal.button_red_method();
    closeErrorModal();
  } else {
    closeErrorModal();
  }
};
</script>
<style lang="scss" scoped>
.picture img {
  margin: auto;
  max-width: 100px;
  max-height: 100px;
}
#modal-error-title {
  margin-top: 10px;
  font-size: 25px !important;
  transition: all 1s ease-out;
  ~ p {
    margin-top: 6px;
  }
}
</style>
