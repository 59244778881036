// import { createApp } from 'vue'
import { createPinia } from "pinia";
import App from './App.vue'
import router from "./router/routes";
import VueRouter from "vue-router";
import * as Vue from "vue";
import { createI18n } from "vue-i18n";
import { api } from "@/plugins/api";
import VueAxios from "vue-axios";
import axios from "axios";
import "@/assets/tailwind.css";
import "@/assets/main.scss";

const es = require("./locales/es.json");
const en = require("./locales/en.json");
const de = require("./locales/de.json");
const it = require("./locales/it.json");
const pt = require("./locales/pt.json");
const fr = require("./locales/fr.json");

const numberFormats = {
    es: {
      currency: {
        style: "currency",
        currency: "EUR"
      }
    },
    en: {
      currency: {
        style: "currency",
        currency: "USD"
      }
    },
    de: {
      currency: {
        style: "currency",
        currency: "EUR"
      }
    },
    it: {
      currency: {
        style: "currency",
        currency: "EUR"
      }
    },
    fr: {
      currency: {
        style: "currency",
        currency: "EUR"
      }
    },
    pt: {
      currency: {
        style: "currency",
        currency: "EUR"
      }
    }
  };
  
  const i18n = createI18n({
    legacy: false,
    warnHtmlInMessage: "off",
    locale: "es",
    fallbackLocale: "es",
    silentTranslationWarn: true,
    numberFormats,
    messages: {
      es,
      en,
      de,
      it,
      pt,
      fr
    }
  });


const app = Vue.createApp(App);
const pinia = createPinia();


pinia.use(api);
app.config.debug = true;
app.config.devtools = true;

app
  .use(i18n)
  .use(VueAxios, axios)
  .provide("axios", app.config.globalProperties.axios)
  .use(router)
  .use(VueRouter)
  .use(pinia);

app.mount("#app");

// createApp(App).mount('#app')
